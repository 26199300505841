import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  static values = { message: String };

  confirm(event) {
    event.preventDefault(); // Evita la acción predeterminada

    Swal.fire({
      title: this.messageValue || "¿Imprimir ticket?",
      text: "Se imprimirá el pedido",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí, imprimir",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.ejecutarImpresion();
      }
    });
  }

  ejecutarImpresion() {
    if (typeof send_pedido === "function") {
      send_pedido(); // Llama a la función de impresión si está definida
    } else {
      console.error("La función send_pedido no está definida.");
    }
  }
}
