import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  static values = { message: String };

  connect() {
    console.log("sr1");
    this.boundConfirm = this.confirm.bind(this);
    this.element.addEventListener("submit", this.boundConfirm);
  }

  disconnect() {
    this.element.removeEventListener("submit", this.boundConfirm);
  }

  confirm(event) {
    event.preventDefault(); // Evita el envío automático del formulario

    const message = this.messageValue || this.element.dataset.confirmMessageValue || "¿Estás seguro de continuar?";
    const submitButton = this.element.querySelector("[type=submit]"); // Encuentra el botón de submit

    Swal.fire({
      title: "Confirmación",
      text: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí, continuar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary"
      }//,
      //buttonsStyling: false
    }).then((result) => {
      if (result.isConfirmed) {
        if (submitButton) {
          submitButton.disabled = true; // 🔥 Deshabilitar botón para evitar doble clic
          submitButton.innerHTML = `<div class="spinner-border spinner-border-sm  text-light" role="status">
                                        <span class="visually-hidden"> Loading...</span>
                                      </div> Espera...`; // Mostrar spinner (opcional)
                                              }

        this.element.removeEventListener("submit", this.boundConfirm); // Evita el loop infinito
        this.element.requestSubmit(); // Enviar el formulario
      }
    });
  }
}
